/* ******************************************************************** */
/* ***  Widget - MENU Base CSS */
/* ******************************************************************** */
.widget-nav-menu {width: 100%; z-index: 600; margin-top: 7px; }
.widget_nav_menu > div {position: relative; z-index: 600; width: 100%; line-height: 0px; display: flex; justify-content: flex-end;}
.widget_nav_menu .menu {display: inline-block; list-style: none; padding: 0; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-padding-start: 0; margin:0}
.widget_nav_menu .menu .menu-item {margin: 0; padding:0; line-height: 0px; display: inline-block; line-height: 1em;}
.widget_nav_menu .menu .menu-item a {display: block; white-space: nowrap; transition: all 0.3s;}
.widget_nav_menu .menu .menu-item .sub-menu {position: relative; display: none; min-width: 200px; margin: 0; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-padding-start: 0; transition: all 0.3s; padding-left:0;}
.widget_nav_menu .menu .menu-item:hover .sub-menu {display: block; position: absolute; bottom: 0; transform: translateY(100%); padding-left: 0;}
.widget_nav_menu .menu .menu-item .sub-menu .menu-item {display: block; position: relative; }
.widget_nav_menu .menu .menu-item .sub-menu:nth-of-type(2) {transform: translateY(100%) translateX(100%);} /* this is needed in the case that they hide a top level page */

/* TOP LEVEL Menu Adjustments */
.widget_nav_menu .menu > .menu-item > a {color: #e2e2e2; background: transparent; font-size:16px; padding: 10px 5px;  font-weight: 700; text-transform: uppercase; line-height:1em; } /* top level */
.widget_nav_menu .menu > .menu-item > a:hover {color: #1e86bf; background: transparent;} /* top level - Hover */
.widget_nav_menu .menu > .menu-item.current-menu-item > a {color: #1e86bf; background: transparent;} /* top level - Active  */
.widget_nav_menu .menu > .menu-item.current-menu-parent > a {color: #1e86bf; background: transparent;} /* Top Level - Active Trail  */

/* Sub Menu Level Adjustments */
.widget_nav_menu .menu .sub-menu > .menu-item > a {color: #e2e2e2; background: #2b353b; font-size: 16px;  font-weight: 400; text-transform: none; padding: 10px 12px 10px; line-height:1em;} /* Sub Menu */
.widget_nav_menu .menu .sub-menu > .menu-item > a:hover {color: #ffffff; background: #1e86bf;} /* Sub Menu - Hover */
.widget_nav_menu .menu .sub-menu > .menu-item.current-menu-item > a {color: #ffffff; background: #1e86bf;} /* Sub Menu - Active Trail */

.nav-mobile {display: none; cursor: pointer; position: absolute; top: 0px; right: 0; height: 56px; width: 56px; font-size: 25px;  margin-right:0; z-index: 2000;} /* Mobile Navigation */
.nav-mobile .menuicon {width: 100%; height:100%; display:flex; justify-content:center; align-items: center; font-family: "dashicons";}
.nav-mobile .menuicon:before {content: "\f349"; color: #eeeeee;} 
.nav-mobile.nav-mobile-open .menuicon:before {color: #1e86bf;}

.widget_nav_menu .menu .menu-item .sub-menu .sub-menu {display: none;}


/* ******************************************************************** */
/* ***  Widget - MENU - Top Row Base CSS */
/* ******************************************************************** */
.widget_nav_menu_top_row {width: 100%; display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap;}
.widget_nav_menu_top_row .centerbox {display: inline-block;}
.widget_nav_menu_top_row .menu {display: inline-block; list-style: none; padding: 0; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-padding-start: 0; margin:0}
.widget_nav_menu_top_row .menu .menu-item {margin: 0; padding:0; line-height: 0px; display: inline-block; line-height: 1em;}
.widget_nav_menu_top_row .menu .menu-item a {color: #e2e2e2; background: transparent; font-size:16px; padding: 10px 5px;  font-weight: 700; text-transform: uppercase; line-height:1em; display: block;}
.widget_nav_menu_top_row .menu .menu-item a:hover {color: #1e86bf;}
.widget_nav_menu_top_row .menu .menu-item.current-menu-item a {color: #1e86bf;}
.widget_nav_menu_top_row .menu .menu-item.current-menu-parent a {color: #1e86bf;}


/* ******************************************************************** */
/* ***  Widget - MENU - Sub Menu Part CSS */
/* ******************************************************************** */
.widget_nav_sub_menu_part {display: inline-block;}
.widget_nav_sub_menu_part h2 {color: #e2e2e2; font-size: calc(12pt + .3vw); text-transform: uppercase;}
.widget_nav_sub_menu_part .menu {padding: 0; margin: 0;}
.widget_nav_sub_menu_part .menu .menu-item {list-style: none;}
.widget_nav_sub_menu_part .menu .menu-item a {color: #2b353b; transition: all 0.3s;}
.widget_nav_sub_menu_part .menu .menu-item a:hover {color: #e2e2e2;}







@media only screen and (max-width: 1100px) {

  /* Mobile MENU tweaks NEW*/
  .widget_nav_menu {width: 100%;}
  .widget_nav_menu > div {height: 56px; padding: 0px 0; margin-top: 0px; }
  .widget_nav_menu .menu {display: none;}
  .widget_nav_menu .menu.nav-active {display: block; position: absolute; bottom: 0; right: 0; transform: translateY(100%);}
  .widget_nav_menu .menu.nav-active .menu-item {display: block; margin-top: -1px;}
  .widget_nav_menu .menu.nav-active .menu-item .sub-menu {display: block; position: relative; transform: translateY(0);}
  
  .widget_nav_menu .menu.nav-active > .menu-item > a {color: #e2e2e2; background: #2b353b; font-size: 18px;  font-weight: 400; text-transform: none; padding: 8px 12px 8px; line-height:1em;} /* top level */
  .widget_nav_menu .menu.nav-active > .menu-item.current-page-ancestor > a {color: #e2e2e2; background: #2b353b;} /* top level - Dont show anscestor page hightlighting */
  .widget_nav_menu .menu.nav-active > .menu-item > a:hover {color: #ffffff; background: #1e86bf;} /* top level - Hover */
  .widget_nav_menu .menu.nav-active > .menu-item.current-menu-item > a {color: #ffffff; background: #1e86bf;} /* top level - Active Trail */
  
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a {color: #e2e2e2; background: #2b353b; font-size: 18px; font-weight: 400; text-transform: none; padding: 8px 34px 8px 34px; line-height:1em; } /* Sub Menu */
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a:hover {color: #ffffff; background: #1e86bf;} /* Sub Menu - Hover */
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item.current-menu-item > a {color: #ffffff; background: #1e86bf;} /* Sub Menu - Active Trail */

  .widget_nav_menu .menu.nav-active .sub-menu .sub-menu .menu-item > a {padding: 8px 34px 8px 56px;} /* sub sub menu*/

  .nav-mobile {display: block; width: 56px; height: 56px;  padding: 0;} /* 3 bar menu block */
  .nav-mobile .menuicon:before {position: relative; left: 0px;}
  .nav {width: 100%; height: 56px; margin-left: 0px;}


}