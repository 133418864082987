/* Starting banner css */
.slideshow-banner-box {position: relative;}
.slideshow-banner-box.slideshow .itembox:nth-child(1) {position: relative; }
.slideshow-banner-box.slideshow .itembox {position: absolute; top: 0; left: 0; z-index: 1; opacity: 0; transition: all 1s;  } 
.slideshow-banner-box.slideshow .itembox.inactive {opacity: 0; z-index: 2;} /* this makes the transition between slides smoother */
.slideshow-banner-box.slideshow .itembox.active {opacity: 1; z-index: 3;}
.slideshow-banner-box .slidebox {line-height: 0;}
.slideshow-banner-box .overlay {position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: space-around; align-items: center; overflow:hidden;}
.slideshow-banner-box .centerbox {display: inline-block; padding: 20px; box-sizing: border-box; text-align: center;}
.slideshow-banner-box .titlewrap {color: #ffffff; font-size: calc(16pt + 2vw);}
.slideshow-banner-box .subtitlewrap {color: #ffffff; font-size: calc(12pt + .5vw); margin: 10px 0 0 0;}
.slideshow-banner-box .buttonwrap {margin: 22px 0 0 0;z-index: 10;}

/*  animate overlay box items  */
.slideshow-banner-box .itembox.active.starting .overlay.animateoverlay-no .centerbox {opacity: 1;} /* this makes sure when not animating the first slide when first starting doesnt just POP in place */
.slideshow-banner-box .itembox.active.starting .overlay.animateoverlay-yes .centerbox {opacity: 0; transform: translateY(100vh);} /* this helps the first slide when first starting the slideshow to animate correct */
.slideshow-banner-box .itembox .overlay.animateoverlay-yes .centerbox {opacity: 0; transform: translateY(100vh);}
.slideshow-banner-box .itembox.active .overlay.animateoverlay-yes .centerbox {transition-delay: .5s; transition: all 1s; transform: translateY(0); opacity: 1;}
.slideshow-banner-box .itembox.inactive .overlay.animateoverlay-yes .centerbox {transform: translateY(0); opacity: 0;}