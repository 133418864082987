
#lpcallouts .full-width-wrapper {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
#lpcallouts .inner {padding-top: 0; padding-bottom: 0;}

#lpcallouts .lp-section div.wp-caption {width:100% !important;} /* if a image comes in with a caption, it creates this div with a INLINE hardset width...causing a break. */
#lpcallouts .element.lp-section p:nth-of-type(1) {margin-top:0;} /* Since we use this 95% of the time with a picture first.  killing the top margin on the P so the pics can get all the way to the tip of the conatiner */

#lpcallouts .element.one-cols {width:100%; margin-bottom:4%;}

#lpcallouts .element.two-cols {width:48%; margin-right:4%; margin-bottom:4%; display:block; float:left;}
#lpcallouts .element.two-cols:nth-child(2n+2) {margin-right:0;}
#lpcallouts .element.two-cols:nth-child(2n+3) {clear:both;}

#lpcallouts .element.three-cols {width:31.33%; margin-right:3%; margin-bottom:3%; display:block; float:left;}
#lpcallouts .element.three-cols:nth-child(3n+3) {margin-right:0;}
#lpcallouts .element.three-cols:nth-child(3n+4) {clear:both;}

#lpcallouts .element.four-cols {width:22.75%; margin-right:3%; margin-bottom:3%; display:block; float:left;}
#lpcallouts .element.four-cols:nth-child(4n+4) {margin-right:0;}
#lpcallouts .element.four-cols:nth-child(4n+5) {clear:both;}

#lpcallouts .element.five-cols {width:18.4%; margin-right:2%; margin-bottom:2%; display:block; float:left;}
#lpcallouts .element.five-cols:nth-child(5n+5) {margin-right:0;}
#lpcallouts .element.five-cols:nth-child(5n+6) {clear:both;}

#lpcallouts .element.six-cols {width:15%; margin-right:2%; margin-bottom:2%; display:block; float:left;}
#lpcallouts .element.six-cols:nth-child(6n+6) {margin-right:0;}
#lpcallouts .element.six-cols:nth-child(6n+7) {clear:both;}


#lpbottom {width: 100%; display: block; margin-top: -2%;}
#lpbottom .inner {padding-top: 0; padding-bottom: 0;}

@media only screen and (max-width:1200px) {
	#lpcallouts .element.six-cols:nth-child(6n+6) {margin-right:2%;}
	#lpcallouts .element.six-cols:nth-child(6n+7) {clear:none;}
	#lpcallouts .element.six-cols {width:18.4%; margin-right:2%; margin-bottom:2%;}
	#lpcallouts .element.six-cols:nth-child(5n+5) {margin-right:0;}
	#lpcallouts .element.six-cols:nth-child(5n+6) {clear:both;}
}

@media only screen and (max-width:1024px) {
	#lpcallouts .element.three-cols:nth-child(3n+3) {margin-right:4%;}
	#lpcallouts .element.three-cols:nth-child(3n+4) {clear:none;}
	#lpcallouts .element.three-cols {width:48%; margin-right:4%; margin-bottom:4%;}
	#lpcallouts .element.three-cols:nth-child(2n+2) {margin-right:0;}
	#lpcallouts .element.three-cols:nth-child(2n+3) {clear:both;}

	#lpcallouts .element.four-cols:nth-child(4n+4) {margin-right:3%;}
	#lpcallouts .element.four-cols:nth-child(4n+5) {clear:none;}
	#lpcallouts .element.four-cols {width:31.33%; margin-right:3%; margin-bottom:3%;}
	#lpcallouts .element.four-cols:nth-child(3n+3) {margin-right:0;}
	#lpcallouts .element.four-cols:nth-child(3n+4) {clear:both;}		
	
	#lpcallouts .element.five-cols:nth-child(5n+5) {margin-right:3%;}
	#lpcallouts .element.five-cols:nth-child(5n+6) {clear:none;}
	#lpcallouts .element.five-cols {width:22.75%; margin-right:3%; margin-bottom:3%;}
	#lpcallouts .element.five-cols:nth-child(4n+4) {margin-right:0;}
	#lpcallouts .element.five-cols:nth-child(4n+5) {clear:both;}

	#lpcallouts .element.six-cols:nth-child(6n+6) {margin-right:3%;}
	#lpcallouts .element.six-cols:nth-child(5n+5) {margin-right:3%;}
	#lpcallouts .element.six-cols:nth-child(5n+6) {clear:none;}
	#lpcallouts .element.six-cols {width:22.75%; margin-right:3%; margin-bottom:3%; }
	#lpcallouts .element.six-cols:nth-child(4n+4) {margin-right:0;}
	#lpcallouts .element.six-cols:nth-child(4n+5) {clear:both;}
}

@media only screen and (max-width:850px) {
	#lpcallouts .element.five-cols:nth-child(4n+4) {margin-right:3%;}
	#lpcallouts .element.five-cols:nth-child(4n+5) {clear:none;}
	#lpcallouts .element.five-cols {width:31.33%; margin-right:3%; margin-bottom:3%;}
	#lpcallouts .element.five-cols:nth-child(3n+3) {margin-right:0;}
	#lpcallouts .element.five-cols:nth-child(3n+4) {clear:both;}
	
	#lpcallouts .element.six-cols:nth-child(4n+4) {margin-right:3%;}
	#lpcallouts .element.six-cols:nth-child(4n+5) {clear:none;}
	#lpcallouts .element.six-cols {width:31.33%; margin-right:3%; margin-bottom:3%; }
	#lpcallouts .element.six-cols:nth-child(3n+3) {margin-right:0;}
	#lpcallouts .element.six-cols:nth-child(3n+4) {clear:both;}
}

@media only screen and (max-width:768px) {
	#lpcallouts .element.four-cols:nth-child(3n+3) {margin-right:4%;}
	#lpcallouts .element.four-cols:nth-child(3n+4) {clear:none;}
	#lpcallouts .element.four-cols {width:48%; margin-right:4%; margin-bottom:4%;}
	#lpcallouts .element.four-cols:nth-child(2n+2) {margin-right:0;}
	#lpcallouts .element.four-cols:nth-child(2n+3) {clear:both;}	
}

@media only screen and (max-width:640px) {
	#lpcallouts .element.two-cols {width:100%; margin-right:0%; margin-bottom:35px; clear:both; }
	#lpcallouts .element.three-cols {width:100%; margin-right:0%; margin-bottom:35px; clear:both; }

	#lpcallouts .element.five-cols:nth-child(5n+5) {margin-right:4%;}
	#lpcallouts .element.five-cols:nth-child(3n+3) {margin-right:4%;}
	#lpcallouts .element.five-cols:nth-child(3n+4) {clear:none;}
	#lpcallouts .element.five-cols {width:48%; margin-right:4%; margin-bottom:4%; }
	#lpcallouts .element.five-cols:nth-child(2n+2) {margin-right:0;}
	#lpcallouts .element.five-cols:nth-child(2n+3) {clear:both;}	
	
	#lpcallouts .element.six-cols:nth-child(5n+5) {margin-right:4%;}
	#lpcallouts .element.six-cols:nth-child(3n+3) {margin-right:4%;}
	#lpcallouts .element.six-cols:nth-child(3n+4) {clear:none;}
	#lpcallouts .element.six-cols {width:48%; margin-right:4%; margin-bottom:4%; }
	#lpcallouts .element.six-cols:nth-child(2n+2) {margin-right:0;}
	#lpcallouts .element.six-cols:nth-child(2n+3) {clear:both;}
}

@media only screen and (max-width:400px) {
	#lpcallouts .element.four-cols {width:100%; margin-right:0%; margin-bottom:35px; clear:both; }
	#lpcallouts .element.five-cols {width:100%; margin-right:0%; margin-bottom:35px; clear:both; }
	#lpcallouts .element.six-cols {width:100%; margin-right:0%; margin-bottom:35px; clear:both; }
}