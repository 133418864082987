/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Roboto:wght@400;500;700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * Core specific resets and layout that generally won't be touched, hence why it is "hidden" here
 */
html {
  overflow-x: hidden;
  overflow-y: scroll; }

html a:focus {
  outline: none; }

a, a:active, a:hover {
  outline: none;
  text-decoration: none;
  cursor: pointer; }

ol {
  list-style-type: decimal;
  padding-left: 40px;
  margin: 1em 0; }

ul {
  list-style-type: disc;
  padding-left: 40px;
  margin: 1em 0; }

li {
  line-height: 1.3em; }

figure {
  padding: 0;
  line-height: 0; }

.screen-reader-response {
  display: none !important; }

.search-form-widget {
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  max-width: 200px; }

.search-results {
  margin: 0 0 30px; }

.search-result {
  margin: 30px 0 0 0;
  padding: 0 0 30px;
  border-bottom: 1px solid #e2e2e2; }
  .search-result img {
    display: none; }
  .search-result span.standout {
    background: black;
    color: white;
    display: inline-block;
    padding: 2px 15px;
    border-radius: 5px; }

/* ******************************************************************** */
/* ***  Widget - MENU Base CSS */
/* ******************************************************************** */
.widget-nav-menu {
  width: 100%;
  z-index: 600;
  margin-top: 7px; }

.widget_nav_menu > div {
  position: relative;
  z-index: 600;
  width: 100%;
  line-height: 0px;
  display: flex;
  justify-content: flex-end; }

.widget_nav_menu .menu {
  display: inline-block;
  list-style: none;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
  margin: 0; }

.widget_nav_menu .menu .menu-item {
  margin: 0;
  padding: 0;
  line-height: 0px;
  display: inline-block;
  line-height: 1em; }

.widget_nav_menu .menu .menu-item a {
  display: block;
  white-space: nowrap;
  transition: all 0.3s; }

.widget_nav_menu .menu .menu-item .sub-menu {
  position: relative;
  display: none;
  min-width: 200px;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
  transition: all 0.3s;
  padding-left: 0; }

.widget_nav_menu .menu .menu-item:hover .sub-menu {
  display: block;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  padding-left: 0; }

.widget_nav_menu .menu .menu-item .sub-menu .menu-item {
  display: block;
  position: relative; }

.widget_nav_menu .menu .menu-item .sub-menu:nth-of-type(2) {
  transform: translateY(100%) translateX(100%); }

/* this is needed in the case that they hide a top level page */
/* TOP LEVEL Menu Adjustments */
.widget_nav_menu .menu > .menu-item > a {
  color: #e2e2e2;
  background: transparent;
  font-size: 16px;
  padding: 10px 5px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1em; }

/* top level */
.widget_nav_menu .menu > .menu-item > a:hover {
  color: #1e86bf;
  background: transparent; }

/* top level - Hover */
.widget_nav_menu .menu > .menu-item.current-menu-item > a {
  color: #1e86bf;
  background: transparent; }

/* top level - Active  */
.widget_nav_menu .menu > .menu-item.current-menu-parent > a {
  color: #1e86bf;
  background: transparent; }

/* Top Level - Active Trail  */
/* Sub Menu Level Adjustments */
.widget_nav_menu .menu .sub-menu > .menu-item > a {
  color: #e2e2e2;
  background: #2b353b;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  padding: 10px 12px 10px;
  line-height: 1em; }

/* Sub Menu */
.widget_nav_menu .menu .sub-menu > .menu-item > a:hover {
  color: #ffffff;
  background: #1e86bf; }

/* Sub Menu - Hover */
.widget_nav_menu .menu .sub-menu > .menu-item.current-menu-item > a {
  color: #ffffff;
  background: #1e86bf; }

/* Sub Menu - Active Trail */
.nav-mobile {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0;
  height: 56px;
  width: 56px;
  font-size: 25px;
  margin-right: 0;
  z-index: 2000; }

/* Mobile Navigation */
.nav-mobile .menuicon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "dashicons"; }

.nav-mobile .menuicon:before {
  content: "\f349";
  color: #eeeeee; }

.nav-mobile.nav-mobile-open .menuicon:before {
  color: #1e86bf; }

.widget_nav_menu .menu .menu-item .sub-menu .sub-menu {
  display: none; }

/* ******************************************************************** */
/* ***  Widget - MENU - Top Row Base CSS */
/* ******************************************************************** */
.widget_nav_menu_top_row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

.widget_nav_menu_top_row .centerbox {
  display: inline-block; }

.widget_nav_menu_top_row .menu {
  display: inline-block;
  list-style: none;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
  margin: 0; }

.widget_nav_menu_top_row .menu .menu-item {
  margin: 0;
  padding: 0;
  line-height: 0px;
  display: inline-block;
  line-height: 1em; }

.widget_nav_menu_top_row .menu .menu-item a {
  color: #e2e2e2;
  background: transparent;
  font-size: 16px;
  padding: 10px 5px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1em;
  display: block; }

.widget_nav_menu_top_row .menu .menu-item a:hover {
  color: #1e86bf; }

.widget_nav_menu_top_row .menu .menu-item.current-menu-item a {
  color: #1e86bf; }

.widget_nav_menu_top_row .menu .menu-item.current-menu-parent a {
  color: #1e86bf; }

/* ******************************************************************** */
/* ***  Widget - MENU - Sub Menu Part CSS */
/* ******************************************************************** */
.widget_nav_sub_menu_part {
  display: inline-block; }

.widget_nav_sub_menu_part h2 {
  color: #e2e2e2;
  font-size: calc(12pt + .3vw);
  text-transform: uppercase; }

.widget_nav_sub_menu_part .menu {
  padding: 0;
  margin: 0; }

.widget_nav_sub_menu_part .menu .menu-item {
  list-style: none; }

.widget_nav_sub_menu_part .menu .menu-item a {
  color: #2b353b;
  transition: all 0.3s; }

.widget_nav_sub_menu_part .menu .menu-item a:hover {
  color: #e2e2e2; }

@media only screen and (max-width: 1100px) {
  /* Mobile MENU tweaks NEW*/
  .widget_nav_menu {
    width: 100%; }
  .widget_nav_menu > div {
    height: 56px;
    padding: 0px 0;
    margin-top: 0px; }
  .widget_nav_menu .menu {
    display: none; }
  .widget_nav_menu .menu.nav-active {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(100%); }
  .widget_nav_menu .menu.nav-active .menu-item {
    display: block;
    margin-top: -1px; }
  .widget_nav_menu .menu.nav-active .menu-item .sub-menu {
    display: block;
    position: relative;
    transform: translateY(0); }
  .widget_nav_menu .menu.nav-active > .menu-item > a {
    color: #e2e2e2;
    background: #2b353b;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    padding: 8px 12px 8px;
    line-height: 1em; }
  /* top level */
  .widget_nav_menu .menu.nav-active > .menu-item.current-page-ancestor > a {
    color: #e2e2e2;
    background: #2b353b; }
  /* top level - Dont show anscestor page hightlighting */
  .widget_nav_menu .menu.nav-active > .menu-item > a:hover {
    color: #ffffff;
    background: #1e86bf; }
  /* top level - Hover */
  .widget_nav_menu .menu.nav-active > .menu-item.current-menu-item > a {
    color: #ffffff;
    background: #1e86bf; }
  /* top level - Active Trail */
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a {
    color: #e2e2e2;
    background: #2b353b;
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    padding: 8px 34px 8px 34px;
    line-height: 1em; }
  /* Sub Menu */
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a:hover {
    color: #ffffff;
    background: #1e86bf; }
  /* Sub Menu - Hover */
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item.current-menu-item > a {
    color: #ffffff;
    background: #1e86bf; }
  /* Sub Menu - Active Trail */
  .widget_nav_menu .menu.nav-active .sub-menu .sub-menu .menu-item > a {
    padding: 8px 34px 8px 56px; }
  /* sub sub menu*/
  .nav-mobile {
    display: block;
    width: 56px;
    height: 56px;
    padding: 0; }
  /* 3 bar menu block */
  .nav-mobile .menuicon:before {
    position: relative;
    left: 0px; }
  .nav {
    width: 100%;
    height: 56px;
    margin-left: 0px; } }

/* Starting banner css */
.slideshow-banner-box {
  position: relative; }

.slideshow-banner-box.slideshow .itembox:nth-child(1) {
  position: relative; }

.slideshow-banner-box.slideshow .itembox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: all 1s; }

.slideshow-banner-box.slideshow .itembox.inactive {
  opacity: 0;
  z-index: 2; }

/* this makes the transition between slides smoother */
.slideshow-banner-box.slideshow .itembox.active {
  opacity: 1;
  z-index: 3; }

.slideshow-banner-box .slidebox {
  line-height: 0; }

.slideshow-banner-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden; }

.slideshow-banner-box .centerbox {
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
  text-align: center; }

.slideshow-banner-box .titlewrap {
  color: #ffffff;
  font-size: calc(16pt + 2vw); }

.slideshow-banner-box .subtitlewrap {
  color: #ffffff;
  font-size: calc(12pt + .5vw);
  margin: 10px 0 0 0; }

.slideshow-banner-box .buttonwrap {
  margin: 22px 0 0 0;
  z-index: 10; }

/*  animate overlay box items  */
.slideshow-banner-box .itembox.active.starting .overlay.animateoverlay-no .centerbox {
  opacity: 1; }

/* this makes sure when not animating the first slide when first starting doesnt just POP in place */
.slideshow-banner-box .itembox.active.starting .overlay.animateoverlay-yes .centerbox {
  opacity: 0;
  transform: translateY(100vh); }

/* this helps the first slide when first starting the slideshow to animate correct */
.slideshow-banner-box .itembox .overlay.animateoverlay-yes .centerbox {
  opacity: 0;
  transform: translateY(100vh); }

.slideshow-banner-box .itembox.active .overlay.animateoverlay-yes .centerbox {
  transition-delay: .5s;
  transition: all 1s;
  transform: translateY(0);
  opacity: 1; }

.slideshow-banner-box .itembox.inactive .overlay.animateoverlay-yes .centerbox {
  transform: translateY(0);
  opacity: 0; }

.social-wrapper .itemwrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.social-wrapper .itembox {
  display: inline-block;
  margin: 3px 5px 3px 0; }

.social-wrapper .itembox:nth-last-child(1) {
  margin-right: 0; }

.social-wrapper .itembox a {
  background: #1e86bf;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s; }

.social-wrapper .itembox a:hover {
  background-color: #2b353b;
  color: #ffffff; }

.social-wrapper .icon {
  line-height: 0px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.social-wrapper .icon:after {
  font-family: "dashicons";
  font-size: calc(40px * 0.7); }

.social-wrapper.round .itembox a {
  border-radius: 50%; }

.social-wrapper.square-rounded-corners .itembox a {
  border-radius: 5px; }

.social-wrapper.no-background .itembox a {
  background-color: transparent; }

.social-wrapper .icon.share:after {
  content: "\f237"; }

.social-wrapper .icon.rss:after {
  content: "\f303"; }

.social-wrapper .icon.email:after {
  content: "\f466"; }

.social-wrapper .icon.networking:after {
  content: "\f325"; }

.social-wrapper .icon.amazon:after {
  content: "\f162"; }

.social-wrapper .icon.facebook:after {
  content: "\f305"; }

.social-wrapper .icon.google:after {
  content: "\f18b"; }

.social-wrapper .icon.instagram:after {
  content: "\f12d"; }

.social-wrapper .icon.linkedin:after {
  content: "\f18d"; }

.social-wrapper .icon.pinterest:after {
  content: "\f192"; }

.social-wrapper .icon.podio:after {
  content: "\f19c"; }

.social-wrapper .icon.reddit:after {
  content: "\f195"; }

.social-wrapper .icon.spotify:after {
  content: "\f196"; }

.social-wrapper .icon.twitch:after {
  content: "\f199"; }

.social-wrapper .icon.twitter:after {
  content: "\f301"; }

.social-wrapper .icon.whatsapp:after {
  content: "\f19a"; }

.social-wrapper .icon.youtube:after {
  content: "\f19b"; }

/* if a custom icon is needed.  You need to roll that icon into a FONT and then add a line like this followin gexample. */
/* .social-wrapper .icon.custom:after {content: "\e900"; font-family: "YourFontName";} */
/* ------------ STARTING BASE CSS RULES ----------------------------------------------- */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 12.5pt;
  font-weight: 400;
  color: #a45749; }

p, ul, ol, li {
  line-height: 1.3em; }

ul ul {
  list-style-type: circle;
  margin: 5px 0; }

strong, b {
  font-weight: 700; }

i {
  font-style: italic; }

h1, h1 a {
  font-size: calc(16pt + .7vw);
  color: #422a25;
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  line-height: 1.2em;
  margin: 10px 0 10px;
  letter-spacing: .5px;
  text-transform: none;
  text-align: left; }

h2, h2 a {
  font-size: calc(16pt + .6vw);
  color: #793425;
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  line-height: 1.1em;
  margin: 10px 0;
  text-transform: none; }

h3, h3 a {
  font-size: calc(16pt + .5vw);
  color: #422a25;
  font-family: "Roboto",sans-serif;
  font-weight: 500;
  line-height: 1.1em;
  margin: 10px 0;
  text-transform: none; }

h4, h4 a {
  font-size: calc(14pt + .4vw);
  color: #793425;
  font-family: "Roboto",sans-serif;
  font-weight: 500;
  line-height: 1.1em;
  margin: 10px 0; }

h5, h5 a {
  font-size: calc(14pt + .3vw);
  color: #422a25;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  line-height: 1.1em;
  margin: 10px 0; }

h6, h6 a {
  font-size: calc(14pt + .2vw);
  color: #793425;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  line-height: 1.1em;
  margin: 10px 0; }

p {
  margin: 1em 0; }

a, a:link, a:visited {
  color: #f49803;
  transition: all 0.3s; }

a:hover, a:focus {
  color: #c47a02; }

.form-item input.wpcf7-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0; }

/* This is a FIX for Safari on a CELL PHONE. for the button displays. */
/* Button Colors */
.form-item input.wpcf7-submit,
.mce-content-body a.button[data-mce-selected],
.af-submit button.acf-button,
button.af-submit-button,
a.button {
  background: #d3793a;
  border: 1px solid #d3793a;
  color: #ffffff;
  font-size: .92em;
  font-family: 'Roboto';
  font-weight: 700;
  padding: 14px 20px;
  width: inherit;
  border-radius: 0px;
  white-space: pre-wrap;
  line-height: 1em;
  display: inline-block;
  text-transform: uppercase;
  box-sizing: border-box;
  transition: all 0.3s; }

/* Button HOVER colors */
.form-item input.wpcf7-submit:hover,
.mce-content-body a.button[data-mce-selected]:hover,
.af-submit button.acf-button:hover,
button.af-submit-button:hover,
a.button:hover {
  background-color: #b34b01;
  border-color: #b34b01;
  color: #ffffff;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }

div.panel.show {
  max-height: 20000px; }

/* accordion fix, to let them open alot further if needed. */
.inner {
  width: 100%;
  max-width: 1475px;
  box-sizing: border-box;
  padding: 10px 2%;
  margin: 0 auto; }

.nopad {
  padding-top: 0;
  padding-bottom: 0; }

.imagewrapper {
  line-height: 0px; }

/* kill off the inherit line height thats on all divs causing 4px at the bottom of a div thats empty */
img {
  max-width: 100%;
  height: auto; }

section:after {
  line-height: 0; }

/*This was causing a 1px white space issue between 2 dark colors.  fix for core. */
/* automatically add a video Play button to a image thats linked as a popup youtube video. */
a.popup-youtube {
  position: relative;
  display: inline-block;
  line-height: 0;
  overflow: hidden; }

a.popup-youtube:after {
  content: "\f522";
  font-family: "dashicons";
  color: rgba(255, 255, 255, 0.7);
  font-size: calc(50px + 5vw);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center; }

a.popup-youtube img {
  transition: all 0.3s; }

a.popup-youtube:hover img {
  transform: scale3d(1.1, 1.1, 1); }

.video-page a.popup-youtube:after {
  display: none; }

/* so the play button doesnt double up on a video page that already has it. */
html body#tinymce {
  padding: 5px 15px !important;
  box-sizing: border-box; }

#content:after {
  content: ' ';
  display: block;
  height: 1px;
  width: 100%;
  clear: both; }

img.alignright {
  float: right;
  padding-left: 20px;
  padding-bottom: 20px; }

/*  -------------- END OF BASE RULES ---------------------------------------------------  */
#header1 {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  padding: 0; }

#header2 {
  background: #999999;
  padding: 0; }

#header3 {
  background: #000000;
  padding: 0; }

#wysiwyg_widgets_widget-2 {
  position: relative;
  z-index: 700;
  width: 100%;
  display: flex;
  justify-content: flex-end; }

#wysiwyg_widgets_widget-2 ul {
  list-style: none;
  margin: 6px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap; }

#wysiwyg_widgets_widget-2 ul li {
  margin-right: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

#wysiwyg_widgets_widget-2 ul li:nth-last-child(1) {
  margin-right: 0; }

#wysiwyg_widgets_widget-2 ul li a {
  color: #ffffff;
  margin-left: 2px;
  font-size: 10pt;
  text-transform: uppercase;
  font-weight: 700; }

#wysiwyg_widgets_widget-2 ul li a:hover {
  color: #b34b01; }

#wysiwyg_widgets_widget-2 ul li.person:before {
  color: #d3793a;
  content: '\f110';
  font-family: 'dashicons';
  font-size: 30px;
  display: inline-block;
  height: 30px;
  width: 30px; }

#wysiwyg_widgets_widget-2 ul li.store:before {
  color: #d3793a;
  content: '\f513';
  font-family: 'dashicons';
  font-size: 25px;
  display: inline-block;
  height: 25px;
  width: 25px; }

#wysiwyg_widgets_widget-2 ul li.cart:before {
  color: #d3793a;
  content: '\f174';
  font-family: 'dashicons';
  font-size: 30px;
  display: inline-block;
  height: 30px;
  width: 30px; }

#blockgroup1 {
  margin-top: -14px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap; }

#blockgroup1 .company-logo {
  position: relative;
  z-index: 700;
  width: 244px;
  margin: 0px 38px; }

#nav_menu-2.widget_nav_menu,
#nav_menu-4.widget_nav_menu {
  margin-top: 40px; }

#nav_menu-5 {
  display: none; }

.widget_nav_menu .menu > .menu-item {
  margin: 0 3.2vw; }

#nav_menu-2.widget_nav_menu .menu > .menu-item:nth-last-child(1) {
  margin-right: 0; }

#nav_menu-4.widget_nav_menu .menu > .menu-item:nth-child(1) {
  margin-left: 0; }

.widget_nav_menu .menu > .menu-item > a {
  font-size: 15px;
  padding: 17px 19px;
  transition: all .5s; }

.widget_nav_menu .menu > .menu-item:hover > a {
  background-color: #d3793a;
  color: #ffffff; }

.widget_nav_menu .menu > .menu-item.current-menu-item > a {
  background-color: #d3793a;
  color: #ffffff; }

.widget_nav_menu .menu > .menu-item.current-menu-parent > a {
  background-color: #d3793a;
  color: #ffffff; }

.widget_nav_menu .menu .menu-item .sub-menu {
  padding-top: 20px;
  min-width: 225px;
  margin-left: -14%; }

.widget_nav_menu .menu .sub-menu > .menu-item > a {
  background: rgba(0, 0, 0, 0.35);
  font-size: 12pt;
  text-transform: uppercase;
  text-align: center; }

.widget_nav_menu .menu .sub-menu > .menu-item > a:hover {
  background-color: #d3793a; }

.widget_nav_menu .menu .sub-menu > .menu-item.current-menu-item > a {
  background-color: #d3793a;
  color: #ffffff; }

.slideshow-banner-box .centerbox {
  padding-top: 8.3vw;
  max-width: 1200px; }

.slideshow-banner-box .titlewrap {
  font-family: 'Courgette';
  margin-bottom: 36px;
  letter-spacing: .1px;
  text-shadow: 3px 5px 3px rgba(0, 0, 0, 0.48); }

.slideshow-banner-box .subtitlewrap {
  max-width: 925px;
  margin: 0 auto;
  color: #fcce83;
  text-shadow: 2px 4px 2px rgba(0, 0, 0, 0.48);
  font-size: calc(14pt + .25vw);
  font-weight: 500;
  letter-spacing: .35px;
  line-height: 1.2em; }

.slideshow-banner-box button {
  padding: 0;
  margin-top: 30px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none; }

.slideshow-banner-box .buttonwrap {
  margin-top: 52px; }

.slideshow-banner-box .buttonwrap a.button {
  font-size: 12pt;
  padding: 20px 40px; }

.is-subpage .slideshow-banner-box .centerbox {
  padding-top: 15vw; }

#content {
  padding: 45px 0; }

#content h1 {
  margin-top: 0;
  margin-bottom: 0px; }

#content .inner .textwrap p {
  line-height: 1.15em;
  margin: 15px 0; }

.page-template-page-product > #content,
.page-template-faq_page > #content,
.page-template-page-landing > #content {
  padding-bottom: 10px; }

.is-homepage #content .inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

.is-homepage #content .inner .leftside {
  width: 47%; }

.is-homepage #content .inner .rightside {
  width: 47%; }

#lr_callouts {
  background: #d2c1b0;
  padding: 38px 0; }

#lr_callouts .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

#lr_callouts .inner .leftside {
  width: 28%; }

#lr_callouts .inner .rightside {
  width: 68.5%; }

#lr_callouts .inner .rightside .callwrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

#lr_callouts .inner .rightside .callwrapper .itembox {
  width: 48%;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

#lr_callouts .inner .rightside .callwrapper .itembox .leftside {
  width: 140px; }

#lr_callouts .inner .rightside .callwrapper .itembox .rightside {
  width: calc(100% - 160px); }

#lr_callouts .inner .rightside .callwrapper .itembox .rightside .titlewrap h5 {
  color: #8f755f;
  font-weight: 700;
  margin-bottom: -5px; }

#lr_callouts .inner .rightside .callwrapper .itembox .rightside .textwrap p {
  color: #998777;
  font-weight: 500;
  letter-spacing: -.3px;
  line-height: 1.15em; }

#midpage_callouts {
  padding: 40px 0; }

#midpage_callouts .inner .section_info .titlewrap h1 {
  margin-bottom: -10px; }

#midpage_callouts .inner .section_info .textwrap p {
  line-height: 1.15em; }

#midpage_callouts .inner .callwrapper {
  margin-top: 55px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

#midpage_callouts .inner .callwrapper .itembox {
  width: 31.5%; }

#midpage_callouts .inner .callwrapper .itembox .lower_info {
  text-align: center;
  margin-top: 44px; }

#midpage_callouts .inner .callwrapper .itembox .lower_info .titlewrap h2 {
  font-size: calc(16pt + .75vw); }

#midpage_callouts .inner .callwrapper .itembox .lower_info .textwrap p {
  font-size: .9em;
  font-weight: 500;
  letter-spacing: .45px; }

#midpage_callouts .inner .callwrapper .itembox .lower_info .buttonwrap {
  margin-top: 55px; }

#midpage_callouts .inner .callwrapper .itembox .lower_info .buttonwrap a.button.more-link {
  padding: 21px 52px; }

#product_section .inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

#product_section .inner .itembox {
  width: 31.5%;
  margin: 0 2.75% 30px 0; }

#product_section .inner .itembox:nth-child(3n+3) {
  margin-right: 0; }

#product_section .inner .itembox .bottom_info {
  padding-top: 45px;
  text-align: center; }

#product_section .inner .itembox .bottom_info .product-title {
  color: #422a25;
  font-size: calc(16pt + .7vw);
  font-weight: 700;
  text-align: center; }

#product_section .inner .itembox .bottom_info .textwrap {
  color: #a45749;
  line-height: 1.1em; }

#product_section .inner .itembox .bottom_info .buttonwrap {
  margin-top: 55px; }

#product_section .inner .itembox .bottom_info .buttonwrap a.button.more-link {
  padding: 20px 52px; }

/* Blog Tweaks */
.archive.category .inner .allitemswrap {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap; }

.archive.category .inner .allitemswrap .itembox.teaser {
  width: 31%;
  margin-right: 3.5%;
  margin-bottom: 3.5vw; }

.archive.category .inner .allitemswrap .itembox.teaser:nth-of-type(3n+3) {
  margin-right: 0px; }

.archive.category .inner .allitemswrap .itembox.teaser:nth-of-type(4n+3) {
  clear: both; }

.archive.category .inner .allitemswrap .itembox.teaser figure {
  border: 1px solid #818285;
  overflow: hidden;
  line-height: 0; }

.archive.category .inner .allitemswrap .itembox.teaser img {
  width: 100%;
  height: auto;
  transition: all 0.3s; }

.archive.category .inner .allitemswrap .itembox.teaser:hover img {
  transform: scale3d(1.1, 1.1, 1);
  -webkit-transform: scale3d(1.1, 1.1, 1); }

.archive.category .inner .allitemswrap .itembox.teaser h2,
.archive.category .inner .allitemswrap .itembox.teaser h2.title {
  font-size: calc(16pt + .35vw);
  color: #000000;
  line-height: 1.1em;
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: none; }

.archive.category .inner .allitemswrap .itembox.teaser .date-date {
  font-size: .8em;
  font-style: italic;
  line-height: 1em;
  margin: 5px 0;
  color: #1d1d1d; }

.archive.category .inner .allitemswrap .itembox.teaser .textwrap {
  line-height: 1.4em; }

.archive.category .inner .allitemswrap .itembox.teaser a.button {
  text-align: center;
  white-space: nowrap;
  width: inherit;
  margin-top: 10px;
  padding: 17px 40px; }

#posts-navigation {
  display: block;
  width: 100%; }

/* Blog Next / Prev pagination tweaks */
.navigation.pagination {
  width: 100%;
  position: relative;
  padding: 1px 0;
  clear: both;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start; }

.navigation.pagination h2 {
  display: none; }

.navigation.pagination .nav-links {
  position: relative; }

.navigation.pagination .nav-links .page-numbers {
  font-size: 12pt;
  background: #d3793a;
  color: #ffffff;
  position: relative;
  width: inherit;
  min-width: 20px;
  padding: 4px 11px 4px;
  border-radius: 17px;
  border: 0;
  display: inline-block;
  margin: 0;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  overflow: hidden;
  z-index: 100;
  text-align: center; }

.navigation.pagination .nav-links .page-numbers.current {
  background: #b34b01; }

.navigation.pagination .nav-links .page-numbers:hover {
  background-color: #b34b01; }

.navigation.pagination .nav-links .next {
  font-size: 12pt;
  background: #d3793a;
  color: #ffffff;
  font-weight: normal;
  position: relative;
  width: inherit;
  padding: 4px 20px 4px 30px;
  border-radius: 0 17px 17px 0;
  border: 0;
  display: inline-block;
  margin: 0 0 0 -12px;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.navigation.pagination .nav-links .next:hover {
  background: #b34b01; }

.navigation.pagination .nav-links .next:after {
  content: ' ';
  width: 33px;
  height: 33px;
  display: inline-block;
  position: absolute;
  left: -17px;
  top: -2px;
  border-radius: 50%;
  background: #ffffff; }

.navigation.pagination .nav-links .prev {
  font-size: 12pt;
  background: #d3793a;
  color: #ffffff;
  font-weight: normal;
  position: relative;
  width: inherit;
  padding: 4px 30px 4px 20px;
  border-radius: 17px 0 0 17px;
  border: 0;
  display: inline-block;
  margin: 0 -12px 0 0;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.navigation.pagination .nav-links .prev:hover {
  background: #b34b01; }

.navigation.pagination .nav-links .prev:after {
  content: ' ';
  width: 33px;
  height: 33px;
  display: inline-block;
  position: absolute;
  right: -17px;
  top: -2px;
  border-radius: 50%;
  background: #ffffff; }

/* FAQ Section */
#faq-section .accordion-wrapper {
  margin-top: 0px; }

#faq-section .accordion-wrapper .accordion .accordion-title {
  background: #d3793a;
  color: #fff;
  font-size: calc(14pt + .2vw);
  font-family: Roboto;
  font-weight: 700;
  line-height: 1.2em;
  padding: 13px 40px 13px 18px;
  text-transform: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  outline: 0;
  margin-bottom: 2px;
  position: relative;
  transition: .4s;
  box-sizing: border-box; }

#faq-section .accordion-wrapper .accordion .accordion-title:hover {
  background-color: #b34b01; }

#faq-section .accordion-wrapper .accordion.active .accordion-title {
  background-color: #b34b01; }

/*#faq-section .accordion-wrapper .accordion .accordion-title {background: #0b6c8f; color: #ffffff; margin-bottom: 3px; }*/
#faq-section .accordion-wrapper .accordion .accordion-title:hover {
  background-color: #b34b01; }

#faq-section .accordion-wrapper .accordion .accordion-title:after {
  color: #fff;
  content: '\f10f';
  font-family: 'dashicons';
  font-size: calc(14pt + .1vw);
  float: right;
  display: block;
  height: 25px;
  width: 25px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0; }

#faq-section .accordion-wrapper .accordion.active .accordion-title {
  background-color: #b34b01; }

#faq-section .accordion-wrapper .accordion.active .accordion-title:after {
  color: #fff;
  content: '\f14f';
  font-family: 'dashicons'; }

#faq-section .accordion-wrapper .accordion.active .accordion-title:hover:after {
  color: #ffffff; }

#faq-section .accordion-wrapper .accordion .accordion-text {
  border-color: #018ffc;
  display: none; }

#faq-section .accordion-wrapper .accordion.active .accordion-text.show {
  display: block; }

#content.single-post .inner {
  display: block; }

#content.single-post .inner h1.title {
  margin-bottom: 3px;
  text-align: left; }

#content.single-post .inner .contentwrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

#content.single-post .inner .post-meta {
  margin-bottom: 0px; }

#content.single-post .inner .date-date {
  font-size: .8em;
  font-style: italic;
  color: #404040; }

#content.single-post .inner .textwrap {
  display: inline-block;
  width: 48%; }

#content.single-post .inner .imagewrapper {
  display: inline-block;
  width: 48%; }

.woocommerce ul.products li.product .button {
  width: 170px;
  text-align: center; }

.woocommerce-page div.product .woocommerce-tabs ul.tabs li.reviews_tab {
  display: none !important; }

.summary.entry-summary .product_meta span {
  display: none; }

.woocommerce div.product form.cart .reset_variations {
  color: #ffffff;
  background-color: #d3793a;
  border-radius: 5px;
  padding: 5px; }

.woocommerce div.product form.cart .reset_variations:hover {
  background-color: #b34b01; }

.woocommerce div.product form.cart .variations select {
  font-size: 12pt; }

.woocommerce .quantity .qty {
  font-size: 12pt; }

.woocommerce div.product form.cart .button.disabled {
  background: #ebebeb;
  color: #422a25;
  border-color: #422a25; }

.woocommerce div.product form.cart .button {
  background-color: #d3793a; }

.woocommerce div.product form.cart .button:hover {
  background-color: #b34b01; }

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  background: #d3793a; }

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button:hover {
  background: #b34b01;
  color: #ffffff; }

.woocommerce button.button.alt {
  background: #d3793a; }

.woocommerce button.button.alt:hover {
  background: #b34b01; }

.woocommerce ul.products li.product .button {
  background-color: #d3793a;
  color: #ffffff; }

.woocommerce ul.products li.product .button:hover {
  background: #b34b01; }

.woocommerce-cart .wc-proceed-to-checkout a.checkout-button {
  padding: 1em; }

.woocommerce a.button {
  padding: 1em; }

.woocommerce form .show-password-input, .woocommerce-page form .show-password-input {
  top: .2em;
  right: .5em; }

.woocommerce ul.products li.product .button {
  padding: 1em; }

.woocommerce .woocommerce-message .button {
  padding: 1em; }

a.button {
  white-space: nowrap; }

#footer1 {
  background: #d3793a; }

#footer2 {
  background: #2d1b16; }

#footer3 {
  background: #333333; }

#footer1 .inner {
  max-width: 100%;
  padding: 0;
  margin: 0; }

#wysiwyg_widgets_widget-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

#wysiwyg_widgets_widget-4 h3 {
  color: #ffffff;
  letter-spacing: -.5px;
  padding: 0 10px; }

#wysiwyg_widgets_widget-4 p {
  margin: 45px 0; }

#wysiwyg_widgets_widget-4 p a.button.more-link {
  background-color: #d3793a;
  color: #ffffff;
  width: 175px;
  height: 62px;
  border-radius: 0;
  border: 1px solid #ffffff;
  padding: 0;
  box-sizing: border-box;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

#wysiwyg_widgets_widget-4 p a.button.more-link:hover {
  background-color: #b34b01;
  border: 1px solid #b34b01; }

.woocommerce #wysiwyg_widgets_widget-4 a.button {
  border: 1px solid #ffffff;
  padding: 0;
  box-sizing: border-box;
  margin: 0 20px;
  text-align: center;
  white-space: nowrap; }

.woocommerce #wysiwyg_widgets_widget-4 a.button:hover {
  border: 1px solid #ffffff;
  padding: 0; }

#wysiwyg_widgets_widget-4 .images_wrap {
  width: 100%;
  line-height: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

#wysiwyg_widgets_widget-4 .images_wrap img {
  width: 33.33%; }

.woocommerce-page table.cart img {
  width: 150px; }

.woocommerce table.cart td.actions .coupon {
  display: none; }

.variation p {
  line-height: 1.55em !important; }

.browser-safari #wysiwyg_widgets_widget-4 p a.button.more-link {
  padding: 0;
  white-space: nowrap;
  width: 200px; }

#wysiwyg_widgets_widget-3 p,
#wysiwyg_widgets_widget-3 .phone a {
  color: #ffffff;
  font-size: calc(16pt + .15vw);
  font-weight: 700;
  margin: 15px 0; }

#wysiwyg_widgets_widget-3 .phone {
  color: #fcd28f;
  font-size: calc(16pt + .15vw);
  font-weight: 700;
  margin: 15px 0; }

#wysiwyg_widgets_widget-3 p a {
  color: #ffffff;
  font-family: 'Roboto';
  font-size: calc(16pt + .15vw);
  font-weight: 700;
  line-height: 1.2em; }

#footer2 .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; }

#footer2 .inner .company-logo {
  width: 242px; }

.social-wrapper .itembox {
  margin-right: 16px; }

.social-wrapper .itembox a {
  background: #d3793a; }

.social-wrapper .itembox a:hover {
  background: #b34b01; }

.social-wrapper .icon {
  width: 50px;
  height: 50px; }

#bfm-credits {
  background: #251612;
  color: #4f322a;
  text-align: center; }

#bfm-credits .inner {
  font-size: .8em;
  line-height: 1.2em; }

#bfm-credits a {
  color: #747b81; }

#bfm-credits a:hover {
  color: #ffffff; }

@media only screen and (max-width: 1550px) {
  .slideshow-banner-box .centerbox {
    padding-top: 20vw; } }

@media only screen and (max-width: 1450px) {
  .is-subpage .slideshow-banner-box .centerbox {
    padding-top: 20vw; } }

@media only screen and (max-width: 1430px) {
  #wysiwyg_widgets_widget-4 h3 {
    text-align: center; }
  #wysiwyg_widgets_widget-4 p {
    margin: 20px 0; }
  #footer1 {
    padding-top: 10px; } }

@media only screen and (max-width: 1250px) {
  .slideshow-banner-box .overlay {
    position: relative;
    background: #2d1b16; }
  .slideshow-banner-box .centerbox {
    padding-top: 20px; }
  .is-subpage .slideshow-banner-box .centerbox {
    padding-top: 20px; }
  .slideshow-banner-box .titlewrap {
    margin: 0; } }

@media only screen and (max-width: 1200px) {
  #lr_callouts .inner .leftside {
    width: 100%; }
  #lr_callouts .inner .leftside .imagewrapper {
    max-width: 100px;
    margin: 10px auto; }
  #lr_callouts .inner .rightside {
    width: 100%; }
  .archive.category .inner .allitemswrap {
    justify-content: space-between; }
  .archive.category .inner .allitemswrap .itembox.teaser {
    width: 48%;
    margin: 0 0 20px; } }

@media only screen and (max-width: 1100px) {
  #nav_menu-2.widget_nav_menu,
  #nav_menu-4.widget_nav_menu {
    display: none; }
  #nav_menu-5 {
    display: block;
    z-index: 300; }
  #blockgroup1 .company-logo {
    position: absolute;
    margin: 0;
    left: 2%;
    top: 0px;
    z-index: 500; }
  .widget_nav_menu .menu.nav-active > .menu-item > a {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 12pt;
    text-transform: uppercase;
    text-align: center; }
  .widget_nav_menu .menu.nav-active .menu-item .sub-menu {
    padding-top: 0; }
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.75);
    font-size: 12pt;
    text-transform: uppercase;
    text-align: center; }
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item.current-menu-item > a {
    background-color: #d3793a; }
  .widget_nav_menu .menu.nav-active > .menu-item.current-menu-item > a {
    background-color: #d3793a; }
  .nav-mobile.nav-mobile-open .menuicon:before {
    color: #d3793a; }
  .widget_nav_menu .menu.nav-active > .menu-item > a:hover {
    background-color: #d3793a; }
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a:hover {
    background-color: #d3793a; }
  .is-homepage #content .inner .leftside {
    width: 100%; }
  .is-homepage #content .inner .rightside {
    width: 100%; }
  .is-homepage #content .inner .rightside img {
    width: 100%; }
  #midpage_callouts .inner .callwrapper {
    justify-content: space-around; }
  #midpage_callouts .inner .callwrapper .itembox {
    width: 48%;
    margin: 20px 0; }
  #product_section .inner {
    justify-content: space-between; }
  #product_section .inner .itembox {
    width: 48%;
    margin: 0 0 30px 0; }
  .widget_nav_menu .menu .menu-item .sub-menu {
    margin-left: 0; } }

@media only screen and (max-width: 900px) {
  #content.single-post .inner .textwrap {
    width: 100%;
    order: 2; }
  #content.single-post .inner .imagewrapper {
    width: 100%;
    order: 1;
    margin-bottom: 20px; } }

@media only screen and (max-width: 800px) {
  #lr_callouts .inner .rightside .callwrapper .itembox {
    width: 100%; } }

@media only screen and (max-width: 700px) {
  .archive.category .inner .allitemswrap .itembox.teaser {
    width: 100%; }
  #wysiwyg_widgets_widget-4 h3 {
    width: 100%;
    text-align: center; }
  #wysiwyg_widgets_widget-4 p {
    width: 100%;
    text-align: center;
    margin: 10px 0; }
  #wysiwyg_widgets_widget-4 p a.button.more-link {
    margin: 0 auto;
    width: 175px; }
  #wysiwyg_widgets_widget-4 p a.button.more-link {
    margin: 0 auto 10px; } }

@media only screen and (max-width: 600px) {
  #midpage_callouts .inner .callwrapper .itembox {
    width: 100%; }
  #product_section .inner .itembox {
    width: 100%; }
  img.alignright {
    float: none;
    padding-left: 0px;
    padding-bottom: 20px;
    margin: 0 auto;
    display: block; } }

@media only screen and (max-width: 500px) {
  #header1 {
    position: relative;
    background-color: #2d1b16; }
  #blockgroup1 .company-logo {
    width: 132px;
    position: relative;
    padding-top: 10px; }
  #wysiwyg_widgets_widget-2 ul {
    justify-content: space-between;
    width: 100%;
    padding-left: 0; }
  #footer2 .inner .company-logo {
    width: 100%; }
  #footer2 .inner .company-logo .imagewrapper {
    width: 200px;
    display: block;
    margin: 0 auto; }
  #footer2 .inner #blockgroup2 {
    text-align: center; }
  .social-wrapper .itemwrapper {
    justify-content: space-around; } }

@media only screen and (max-width: 400px) {
  #wysiwyg_widgets_widget-2 ul li {
    margin: 0 10px; }
  #lr_callouts .inner .rightside .callwrapper .itembox .leftside {
    width: 100%; }
  #lr_callouts .inner .rightside .callwrapper .itembox .rightside {
    width: 100%;
    text-align: center; } }

#lpcallouts .full-width-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

#lpcallouts .inner {
  padding-top: 0;
  padding-bottom: 0; }

#lpcallouts .lp-section div.wp-caption {
  width: 100% !important; }

/* if a image comes in with a caption, it creates this div with a INLINE hardset width...causing a break. */
#lpcallouts .element.lp-section p:nth-of-type(1) {
  margin-top: 0; }

/* Since we use this 95% of the time with a picture first.  killing the top margin on the P so the pics can get all the way to the tip of the conatiner */
#lpcallouts .element.one-cols {
  width: 100%;
  margin-bottom: 4%; }

#lpcallouts .element.two-cols {
  width: 48%;
  margin-right: 4%;
  margin-bottom: 4%;
  display: block;
  float: left; }

#lpcallouts .element.two-cols:nth-child(2n+2) {
  margin-right: 0; }

#lpcallouts .element.two-cols:nth-child(2n+3) {
  clear: both; }

#lpcallouts .element.three-cols {
  width: 31.33%;
  margin-right: 3%;
  margin-bottom: 3%;
  display: block;
  float: left; }

#lpcallouts .element.three-cols:nth-child(3n+3) {
  margin-right: 0; }

#lpcallouts .element.three-cols:nth-child(3n+4) {
  clear: both; }

#lpcallouts .element.four-cols {
  width: 22.75%;
  margin-right: 3%;
  margin-bottom: 3%;
  display: block;
  float: left; }

#lpcallouts .element.four-cols:nth-child(4n+4) {
  margin-right: 0; }

#lpcallouts .element.four-cols:nth-child(4n+5) {
  clear: both; }

#lpcallouts .element.five-cols {
  width: 18.4%;
  margin-right: 2%;
  margin-bottom: 2%;
  display: block;
  float: left; }

#lpcallouts .element.five-cols:nth-child(5n+5) {
  margin-right: 0; }

#lpcallouts .element.five-cols:nth-child(5n+6) {
  clear: both; }

#lpcallouts .element.six-cols {
  width: 15%;
  margin-right: 2%;
  margin-bottom: 2%;
  display: block;
  float: left; }

#lpcallouts .element.six-cols:nth-child(6n+6) {
  margin-right: 0; }

#lpcallouts .element.six-cols:nth-child(6n+7) {
  clear: both; }

#lpbottom {
  width: 100%;
  display: block;
  margin-top: -2%; }

#lpbottom .inner {
  padding-top: 0;
  padding-bottom: 0; }

@media only screen and (max-width: 1200px) {
  #lpcallouts .element.six-cols:nth-child(6n+6) {
    margin-right: 2%; }
  #lpcallouts .element.six-cols:nth-child(6n+7) {
    clear: none; }
  #lpcallouts .element.six-cols {
    width: 18.4%;
    margin-right: 2%;
    margin-bottom: 2%; }
  #lpcallouts .element.six-cols:nth-child(5n+5) {
    margin-right: 0; }
  #lpcallouts .element.six-cols:nth-child(5n+6) {
    clear: both; } }

@media only screen and (max-width: 1024px) {
  #lpcallouts .element.three-cols:nth-child(3n+3) {
    margin-right: 4%; }
  #lpcallouts .element.three-cols:nth-child(3n+4) {
    clear: none; }
  #lpcallouts .element.three-cols {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%; }
  #lpcallouts .element.three-cols:nth-child(2n+2) {
    margin-right: 0; }
  #lpcallouts .element.three-cols:nth-child(2n+3) {
    clear: both; }
  #lpcallouts .element.four-cols:nth-child(4n+4) {
    margin-right: 3%; }
  #lpcallouts .element.four-cols:nth-child(4n+5) {
    clear: none; }
  #lpcallouts .element.four-cols {
    width: 31.33%;
    margin-right: 3%;
    margin-bottom: 3%; }
  #lpcallouts .element.four-cols:nth-child(3n+3) {
    margin-right: 0; }
  #lpcallouts .element.four-cols:nth-child(3n+4) {
    clear: both; }
  #lpcallouts .element.five-cols:nth-child(5n+5) {
    margin-right: 3%; }
  #lpcallouts .element.five-cols:nth-child(5n+6) {
    clear: none; }
  #lpcallouts .element.five-cols {
    width: 22.75%;
    margin-right: 3%;
    margin-bottom: 3%; }
  #lpcallouts .element.five-cols:nth-child(4n+4) {
    margin-right: 0; }
  #lpcallouts .element.five-cols:nth-child(4n+5) {
    clear: both; }
  #lpcallouts .element.six-cols:nth-child(6n+6) {
    margin-right: 3%; }
  #lpcallouts .element.six-cols:nth-child(5n+5) {
    margin-right: 3%; }
  #lpcallouts .element.six-cols:nth-child(5n+6) {
    clear: none; }
  #lpcallouts .element.six-cols {
    width: 22.75%;
    margin-right: 3%;
    margin-bottom: 3%; }
  #lpcallouts .element.six-cols:nth-child(4n+4) {
    margin-right: 0; }
  #lpcallouts .element.six-cols:nth-child(4n+5) {
    clear: both; } }

@media only screen and (max-width: 850px) {
  #lpcallouts .element.five-cols:nth-child(4n+4) {
    margin-right: 3%; }
  #lpcallouts .element.five-cols:nth-child(4n+5) {
    clear: none; }
  #lpcallouts .element.five-cols {
    width: 31.33%;
    margin-right: 3%;
    margin-bottom: 3%; }
  #lpcallouts .element.five-cols:nth-child(3n+3) {
    margin-right: 0; }
  #lpcallouts .element.five-cols:nth-child(3n+4) {
    clear: both; }
  #lpcallouts .element.six-cols:nth-child(4n+4) {
    margin-right: 3%; }
  #lpcallouts .element.six-cols:nth-child(4n+5) {
    clear: none; }
  #lpcallouts .element.six-cols {
    width: 31.33%;
    margin-right: 3%;
    margin-bottom: 3%; }
  #lpcallouts .element.six-cols:nth-child(3n+3) {
    margin-right: 0; }
  #lpcallouts .element.six-cols:nth-child(3n+4) {
    clear: both; } }

@media only screen and (max-width: 768px) {
  #lpcallouts .element.four-cols:nth-child(3n+3) {
    margin-right: 4%; }
  #lpcallouts .element.four-cols:nth-child(3n+4) {
    clear: none; }
  #lpcallouts .element.four-cols {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%; }
  #lpcallouts .element.four-cols:nth-child(2n+2) {
    margin-right: 0; }
  #lpcallouts .element.four-cols:nth-child(2n+3) {
    clear: both; } }

@media only screen and (max-width: 640px) {
  #lpcallouts .element.two-cols {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 35px;
    clear: both; }
  #lpcallouts .element.three-cols {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 35px;
    clear: both; }
  #lpcallouts .element.five-cols:nth-child(5n+5) {
    margin-right: 4%; }
  #lpcallouts .element.five-cols:nth-child(3n+3) {
    margin-right: 4%; }
  #lpcallouts .element.five-cols:nth-child(3n+4) {
    clear: none; }
  #lpcallouts .element.five-cols {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%; }
  #lpcallouts .element.five-cols:nth-child(2n+2) {
    margin-right: 0; }
  #lpcallouts .element.five-cols:nth-child(2n+3) {
    clear: both; }
  #lpcallouts .element.six-cols:nth-child(5n+5) {
    margin-right: 4%; }
  #lpcallouts .element.six-cols:nth-child(3n+3) {
    margin-right: 4%; }
  #lpcallouts .element.six-cols:nth-child(3n+4) {
    clear: none; }
  #lpcallouts .element.six-cols {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 4%; }
  #lpcallouts .element.six-cols:nth-child(2n+2) {
    margin-right: 0; }
  #lpcallouts .element.six-cols:nth-child(2n+3) {
    clear: both; } }

@media only screen and (max-width: 400px) {
  #lpcallouts .element.four-cols {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 35px;
    clear: both; }
  #lpcallouts .element.five-cols {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 35px;
    clear: both; }
  #lpcallouts .element.six-cols {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 35px;
    clear: both; } }
